nav {
    background: rgba(0, 0, 0, 0.4);
    width: max-content;
    padding: 0.7rem 0.7rem;
    z-index: 2;
    position: fixed;
    /* left: 50%; */
    transform: translateX(-50%);
    bottom: 18rem;
    gap: 0.8rem;
    border-radius: 5rem;
    right: 5rem;
}
nav button {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav button:hover {
    background: rgba(49, 49, 49, 0.653);
    cursor: pointer;

}

nav button.active {
    background: var(--color-light);
    color: var(--color-white);

}


/* ====================== Media Queries (Medium Devices) ============== */
@media screen and (max-width: 1024px) {
    nav {
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        bottom: 2rem;
    }
}

/* ====================== Media Queries (Small Devices) ============== */
@media screen and (max-width: 600px) {
    nav {
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        bottom: 2rem;
    }
}