header {
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ===== CTA =====*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ==== Socials ===== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ====== ME ===== */
.me {
    /* background: linear-gradient(5deg, var(--color-primary-variant), transparent); */
    width: 26rem;
    height: 30rem;
    position: relative;
    /* position: absolute; */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 12rem 12rem;
    /* overflow: hidden; */
    padding: 2.5rem 1.5rem 1.5rem;
}

.me-img {
    position: relative;
    /* left: 100%; */
    border-radius: 10rem;
    /* height: 60px; */
    overflow: hidden;
    height: 100%;
    /* width: 30%; */
}

/* === SCROLL DOWN === */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-primary)
}

.scroll__down:hover {
    color: white;
}


/* +=== MEDIA Queries (Medium Devices) ==== */

/* ====================== Media Queries (Medium Devices) ============== */
/* @media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
}

@media screen and (max-width: 1024px) and (max-height: 600px){
    header {
        height: 140vh;
    }
} */

/* ====================== Media Queries (Small Devices) ============== */
@media screen and (max-width: 600px) {
    /* header {
        height: 100vh;
    } */

    .header__socials,
    .scroll__down {
        display: none;
    }
}

@media screen and (max-width: 375px) {
    /* header {
        height: 150vh;
    } */

    .header__socials,
    .scroll__down {
        display: none;
    }
}