.project-desc {
    padding: 3rem 5rem;
    margin-top: 0px;
}

.project-title {
    text-decoration: underline;
    margin-top: 0px;
}

#return {
    margin-bottom: 0px;
}