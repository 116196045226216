.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio-image {
    height: 300px;
    width: 300px;
}

.portfolio__item h3 {
    margin: 1.2rem 0 1rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

#read-more {
    color: rgb(171, 171, 171);
}

#read-more:hover {
    color: white;
}

#tools {
    color: rgb(191, 191, 191);
    font-size: 12px;
    margin-bottom: 0.5rem;
}

#tools:hover {
    color: white;
}


@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ====================== Media Queries (Small Devices) ============== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}